import React from "react";
import "./message.styles.scss";

const Message = (props) => {
  return (
    <div className="message">
      <span>{props.message.name}</span>
      <span>{props.message.time}</span>
      <p>{props.message.msg}</p>
    </div>
  );
};

export default Message;
