import React from "react";
import CustomButton from "../../components/custom-button/custom-button.component";
import UserContext from "../../UserContext";
import UserAccessPage from "../admin/user-access.component";
import LoginLogoutReport from "../admin/login-logout-report.component";
import Tabs from "../../components/tabs/tabs.component";
import { getAllUsers } from "../../firebase/firebase.utils";
import { CSVLink } from "react-csv";
import { reformatDateColumn } from "../../utils/service.util";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { allUsers: null };
  }
  async componentDidMount() {
    // TODO figure out window.location.reload that's using /audience?loadOnce=true kind of approach
    // console.log('ADMIN mount');
    // setTimeout(() => {
    //   window.location.reload();
    // }, 5000);
    // const allUsers = await getAllUsers();
    // this.setState({ allUsers: allUsers }, () =>
    //   console.log("ALLUSERS", allUsers)
    // );
  }

  // tabs = [
  //   {
  //     id: 1,
  //     name: "Users Management",
  //     title: "",
  //     content: <UserAccessPage />,
  //   },
  //   {
  //     id: 2,
  //     name: "Login Logout Report",
  //     title: "",
  //     content: <LoginLogoutReport />,
  //   },
  // ];
  render() {
    return (
      <UserContext.Consumer>
        {(context) => {
          console.log(`Admin's context`, context);
          if (context.user) {
            return context.user.role && context.user.role.includes("adm") ? (
              <React.Fragment>
                <div className="admin-container">
                  <h2>Admin Page</h2>
                  <CustomButton
                    onClick={async () => {
                      const users = await getAllUsers();
                      const usersReformat = reformatDateColumn(
                        users,
                        "lastLogin"
                      );
                      this.setState({ allUsers: usersReformat });

                      console.log("All Users Report Button Clicked:", users);
                    }}
                    style={{ marginBottom: "20px" }}
                  >
                    {" "}
                    ALL Users Report
                  </CustomButton>
                  {this.state.allUsers ? (
                    <CSVLink data={this.state.allUsers}>
                      File Ready. Click Here.
                    </CSVLink>
                  ) : null}
                  {/* <Tabs tabs={this.tabs} /> */}
                </div>
              </React.Fragment>
            ) : (
              <h2>You're not authorized as Administrator.</h2>
            );
          } else {
            return <h1>Loading...</h1>;
          }
        }}
      </UserContext.Consumer>
    );
  }
}

export default Admin;
