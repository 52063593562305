import React from 'react';
import Navigation from './navigation.component';
import Tab from './tab.component';

import './tabs.styles.scss';

/// Collection of tabs component
const Tabs = (props) => {
  const [activeTabId, setActiveTab] = React.useState(props.tabs[0].id);

  const activeTab = React.useMemo(
    () => props.tabs.find((tab) => tab.id === activeTabId),
    [activeTabId, props.tabs]
  );

  return (
    <div className='tabs'>
      <Navigation
        tabs={props.tabs}
        onNavClick={setActiveTab}
        activeTabId={activeTabId}
      />
      <Tab tab={activeTab} />
    </div>
  );
};
export default Tabs;
