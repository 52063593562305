import React from "react";
import "./tabs.styles.scss";
import { firestore } from "../../firebase/firebase.utils";

firestore.collection("users").onSnapshot(function (snapshot) {
  snapshot.docChanges().forEach(function (change) {
    if (change.type === "added") {
      // console.log("New user: ", change.doc.data());
    }
    if (change.type === "modified") {
      // console.log("Modified user: ", change.doc.data());
    }
    if (change.type === "removed") {
      // console.log("Removed user: ", change.doc.data());
    }
  });
});
/// The Tab Content component itself
const Tab = (props) => {
  return (
    <div className={`tabs__content`}>
      <div>{props.tab.content}</div>
    </div>
  );
};

export default Tab;
