import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import UserContext from "../../UserContext";
import { APIUrl } from "../../utils/config.util";
import CustomButton from "../custom-button/custom-button.component";

class UserInfo extends React.Component {
  // const [user, setUser] = useState(null);
  // const userContext = useContext(UserContext);
  // useEffect(() => {
  //   setUser(usr);
  // }, [usr]); // interesting it point dependency to non-state member
  showRegisterButton(path) {
    switch (path) {
      case "/":
        return true;
      default:
        // all other routes don't show Register Free button~
        return false;
    }
  }
  navSlide = () => {
    const burger = document.querySelector(".burger");

    if (burger) {
      const nav = document.querySelector(".nav-links");

      burger.addEventListener("click", () => {
        nav.classList.toggle("nav-active");
      });
    } // when burger is hidden
  };
  componentDidMount() {
    this.navSlide();
  }

  render() {
    const { history, toggleBurger } = this.props;
    // setTimeout(this.navSlide(), 5000);
    // console.log("CURRENT HISTORY", history);
    return (
      <UserContext.Consumer>
        {(context) => {
          // if (context && context.user) initBurger();
          // console.log("UserInfo12", context);
          return (
            <div className="user-info">
              {context ? (
                context.user ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomButton
                      id="endSession"
                      onClick={() => {
                        // nullify the user, redirect to /, and call server to expire the cookie
                        context.setUser(null);
                        axios.get(APIUrl + "/logout", {
                          withCredentials: true,
                        });
                        toggleBurger();
                        history.push("/");
                      }}
                    >
                      End Session
                    </CustomButton>
                  </div>
                ) : null
              ) : null}
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default withRouter(UserInfo);
