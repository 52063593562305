import React from "react";
import { withRouter } from "react-router-dom";
import "./audience.styles.scss";
import VideoPlayer from "../../components/video-player/video-player.component";
import axios from "axios";
import { APIUrl } from "../../utils/config.util";
import AudienceTabs from "./audience-tabs.component";
import {
  firestore,
  insertNewSignal,
  deleteSignal,
} from "../../firebase/firebase.utils";
import CustomButton from "../../components/custom-button/custom-button.component";
import UserContext from "../../UserContext";
import Bios from "../../components/speakers/bio.component";
import Overview from "../../pages/overview/overview.component";

const VIMEO_URL =
  "https://player.vimeo.com/video/499506490?controls=1&autoplay=1&loop=1&muted=1&title=0";

class Audience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wowzaUrl: "",
      isWowzaPlayerHidden: true,
      isShowLinkButtonClicked: false,
      loopVideoHeight: 506,
      loopVideoWidth: 900,
    };
  }

  componentDidMount() {
    axios
      .get(APIUrl + "/play", { withCredentials: true })
      .then((result) => {
        console.log("Audience componentDidMount result", result);
        if (result) {
          this.setState({ wowzaUrl: result.data.result });
        }

        const thisComponent = this;

        // listen to SIGNALS
        firestore.collection("signals").onSnapshot(function (snapshot) {
          // logic for 'added' - meaning detect any 'LIVE' signal record exist
          if (
            snapshot
              .docChanges()
              .some((change) =>
                thisComponent.isLiveSignal(change, thisComponent)
              )
          ) {
            console.log("Audience - got LIVE signal from DB");
            // also pause the loop video
            const iframe = document.getElementById("audienceVideoLoop");
            console.log("PLAY BUTTON", iframe);
            if (iframe) iframe.setAttribute("src", "");
            thisComponent.setState({ isWowzaPlayerHidden: false });
          }
          // LIVE signal removed (any of LIVE record being removed)
          else if (
            snapshot
              .docChanges()
              .some((change) => thisComponent.isRemoveSignal(change))
          ) {
            const iframe = document.getElementById("audienceVideoLoop");
            if (iframe) iframe.setAttribute("src", VIMEO_URL);
            thisComponent.setState({ isWowzaPlayerHidden: true });
          }
          // LINK1 signal
          else if (
            snapshot
              .docChanges()
              .some((change) => thisComponent.isLink1Signal(change))
          ) {
            thisComponent.setState({ isShowLinkButtonClicked: true });
          }
        });
      })
      .catch((error) =>
        console.log("Audience ERROR ComponentDidMount API Call", error)
      );

    // listen to screen width change too
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    if (window.innerWidth <= 320) {
      this.setState({ loopVideoWidth: 305, loopVideoHeight: 180 });
    } else if (window.innerWidth <= 375) {
      this.setState({ loopVideoWidth: 355, loopVideoHeight: 200 });
    } else if (window.innerWidth <= 414) {
      this.setState({ loopVideoWidth: 392, loopVideoHeight: 280 });
    } else if (window.innerWidth <= 800) {
      this.setState({ loopVideoWidth: 720, loopVideoHeight: 403 });
    } else if (window.innerWidth <= 1024) {
      this.setState({ loopVideoWidth: 900, loopVideoHeight: 506 });
    } else if (window.innerWidth <= 1112) {
      this.setState({ loopVideoWidth: 1000, loopVideoHeight: 560 });
    } else if (window.innerWidth <= 1366) {
      this.setState({ loopVideoWidth: 1000, loopVideoHeight: 560 });
    } else {
      this.setState({ loopVideoWidth: 1100, loopVideoHeight: 616 });
    }
  }

  isRemoveSignal(change) {
    if (change.type === "removed") {
      const { status } = change.doc.data();
      console.log("REMOVED", status);
      return status === "LIVE"; // status that being removed is LIVE
    }
    return false;
  }

  isLiveSignal(change) {
    if (change.type === "added") {
      const { status } = change.doc.data();
      console.log("ADD Live SIGNALS - Status", status);
      return status === "LIVE";
    }
    return false;
  }

  isLink1Signal(change) {
    if (change.type === "added") {
      const { status } = change.doc.data();
      console.log("ADD Link1 SIGNALS - Status", status);
      return status === "LINK1";
    }
    return false;
  }

  render() {
    const wowzaPlayerClass = this.state.isWowzaPlayerHidden
      ? "wowza-player-hidden"
      : "";

    // console.log("this.isWowzaPlayerHidden", this.state.isWowzaPlayerHidden);
    // console.log("WOWZA_player_class", wowzaPlayerClass);
    return this.state.wowzaUrl === "" ? (
      <div style={{ height: "52vh", marginTop: "60px" }}>Loading...</div>
    ) : (
      <UserContext.Consumer>
        {(context) => {
          console.log("Audience - Admin Context", context);
          return (
            <div className="audience-homepage">
              <div className="audience-description">
                <h1>
                  {context.user &&
                  context.user.role &&
                  context.user.role.includes("adm") ? (
                    <span className="admin-buttons">
                      <CustomButton
                        isCta
                        onClick={() => {
                          insertNewSignal("LIVE");
                          // console.log("START WEBINAR CLICKED - this", this);
                        }}
                        isDisabled={!this.state.isWowzaPlayerHidden}
                      >
                        START WEBINAR
                      </CustomButton>
                      <CustomButton
                        isCta
                        onClick={() => {
                          deleteSignal("LIVE");
                          // console.log("STOP WEBINAR CLICKED - this", this);
                        }}
                      >
                        STOP WEBINAR
                      </CustomButton>
                      <CustomButton
                        isCta
                        onClick={() => {
                          insertNewSignal("LINK1");
                          // console.log("SHOW LINK CLICKED - this", this);
                          this.setState({ isShowLinkButtonClicked: true });
                        }}
                      >
                        SHOW LINK
                      </CustomButton>
                    </span>
                  ) : null}
                </h1>
                <h1 className="audience-title">Virtual Media Preview</h1>
              </div>
              <div className="audience-video">
                <div className="loop-video">
                  <iframe
                    id="audienceVideoLoop"
                    className={`${
                      wowzaPlayerClass ? "" : "wowza-player-hidden"
                    }`}
                    title="loopVideo"
                    src={VIMEO_URL}
                    width={this.state.loopVideoWidth}
                    height={this.state.loopVideoHeight}
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
                <VideoPlayer
                  wowzaPlayerClass={wowzaPlayerClass}
                  wowzaUrl={this.state.wowzaUrl}
                />
              </div>
              {/* the chatbox area */}
              <div className="audience-sidebar">
                <AudienceTabs />
              </div>
              {/* the overview and biographies area */}
              <Overview className="audience-overview" />
              <Bios className="audience-biographies" />
              <div
                className={`audience-popup ${
                  this.state.isShowLinkButtonClicked
                    ? ""
                    : "audience-popup-hidden"
                }`}
              >
                <p>
                  Be one of the first to experience Hydeout: The Prelude. This
                  freeplay is open for 2 hours, so have fun exploring!
                </p>
                <p>
                  P.S. We've emailed you your login details (check your junk
                  mail!). You may reach us at hello@hydeoutworld.com for any
                  issues.
                </p>

                <CustomButton
                  onClick={() => {
                    this.setState({ isShowLinkButtonClicked: false });
                  }}
                >
                  <a
                    href="https://www.hydeoutworld.com/account/login/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Free Play!
                  </a>
                </CustomButton>

                <CustomButton
                  href=""
                  onClick={() => {
                    this.setState({ isShowLinkButtonClicked: false });
                  }}
                >
                  X
                </CustomButton>
              </div>
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default withRouter(Audience);
