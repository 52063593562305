import React from "react";
import { withRouter } from "react-router-dom";
import UserContext from "../../UserContext";
import { login } from "../../utils/auth.util";

// for Hydeout, implement login logic inside register too
class RegisterSuccess extends React.Component {
  login = (context) => {
    const { history } = this.props;
    try {
      console.log("REGISTER SUCCESS context", context.user);
      console.log("REGISTER SUCCESS props", this.props);

      login(context.user.uniqueCode, context.user.email).then((res) => {
        // console.log('DATA from login', res);
        let isValidUser = res.status === 200 && res.data.result === "logged_in";

        // document.cookie = `tokenxcl=${data.data.token}`; // no more cookies!
        // if VALID jump to audience page (post-login area)
        if (isValidUser) {
          // since it's login, we don't use Auth.isAuthenticated()
          this.setState({ isShowMessage: false });
          // TODO set user context too here!
          context.setUser(res.data.userInfo);
          // set user to UserContext
          history.push("/audience");
        } else {
          this.setState({ isShowMessage: true });
        }
      });
    } catch (error) {
      console.error(`ERROR - Login, ${error}`);
    }
  };

  render() {
    return (
      <UserContext.Consumer>
        {(context) => {
          this.login(context);
          return (
            <React.Fragment>
              <h3>Congratulations, you have registered your attendance.</h3>
              <p>Loading the event...</p>
            </React.Fragment>
          );
        }}
      </UserContext.Consumer>
    );
  }
}
export default withRouter(RegisterSuccess);
