import React from "react";
import { withRouter } from "react-router-dom";
import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import { createUserProfileDocument } from "../../firebase/firebase.utils";
import { Message } from "semantic-ui-react";
import { login } from "../../utils/auth.util";
import UserContext from "../../UserContext";
import Overview from "../../pages/overview/overview.component";
import Bios from "../../components/speakers/bio.component";

import "./register.styles.scss";

/// Register Attendance and auto login
class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      isShowFailMessage: false,
    };
  }

  login = async (context, userUniqueCode, email) => {
    const { history } = this.props;
    console.log(
      "REGISTER - context,userUniqueCode, email",
      context,
      userUniqueCode,
      email
    );
    try {
      // this validateUser is much more stable to call to firebase
      await login(userUniqueCode, email).then((res) => {
        // console.log("DATA from LOGIN thru REGISTER Page", res);
        let isValidUser = res.status === 200 && res.data.result === "logged_in";

        // document.cookie = `tokenxcl=${data.data.token}`; // no more cookies!
        // if VALID jump to audience page (post-login area)
        if (isValidUser) {
          // since it's login, we don't use Auth.isAuthenticated()
          this.setState({ isShowMessage: false });
          // TODO set user context too here!
          context.setUser(res.data.userInfo);
          // set user to UserContext
          history.push("/audience");
        } else {
          this.setState({ isShowMessage: true });
        }
      });
    } catch (error) {
      console.error(`ERROR - Login, ${error}`);
    }
  };

  handleSubmit = async (event, context) => {
    event.preventDefault();

    this.setState({ isShowMessage: false });

    const { firstName, lastName, email } = this.state;
    const user = { firstName, lastName, email };
    try {
      const result = await createUserProfileDocument(user);
      if (result.result === "EMAIL_EXIST") {
        // both email exist and success will now go to login
        // this is more of REGISTER ATTENDANCE mechanism
        this.login(context, result.uniqueCode, email);
      } else if (result.result === "SUCCESS") {
        console.log("REGISTER SUBMIT SUCCESS", email, result);
        this.login(context, result.uniqueCode, email);
      }
    } catch (error) {
      console.error(`Error on creating User, ${error}`);
    }
  };

  // generic patten to handle all input textboxes (4 in this cases)
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { firstName, lastName, email } = this.state;
    return (
      <UserContext.Consumer>
        {(context) => {
          return (
            <div className="home">
              <div className="sign-up">
                <h2 className="title">Register my attendance</h2>
                <span>
                  Fill in a little info about yourself to mark your attendance.
                </span>
                <form
                  className="sign-up-form"
                  onSubmit={(evt) => this.handleSubmit(evt, context)}
                >
                  <FormInput
                    type="text"
                    value={firstName}
                    name="firstName"
                    onChange={this.handleChange}
                    label="First Name"
                    required
                  />
                  <FormInput
                    type="text"
                    value={lastName}
                    name="lastName"
                    onChange={this.handleChange}
                    label="Last Name"
                    required
                  />
                  <FormInput
                    type="email"
                    value={email}
                    name="email"
                    onChange={this.handleChange}
                    label="Email"
                    required
                  />
                  <CustomButton>Register</CustomButton>
                  {this.state.isShowFailMessage ? (
                    <Message negative>
                      <Message.Header>
                        This email has been registered.
                      </Message.Header>
                      <p>Please contact your organizer.</p>
                    </Message>
                  ) : null}
                </form>
              </div>
              <Overview className="home-overview" />
              <Bios className="home-biographies" />
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default withRouter(Register);
