import React from "react";

const Overview = (props) => (
  <div className={props.className}>
    <h3>
      <p>
        Hydeout: The Prelude is an immersive digital music entertainment
        platform that features more than 40 international artists, exclusive
        on-demand new performances and extraordinary stories of today's top
        music artists in a 40-part episodic series.{" "}
      </p>
      <p>
        The Prelude will feature on-demand exclusive performances at
        breathtaking locations around the world as well as 3D virtual
        environments, as we follow international music acts on their journeys.
        Get up close and personal with them through untold narratives in this
        series of incredible story arcs.
      </p>
      <p>
        Additionally, social interaction features such as games, chat rooms and
        avatar customization will allow users to perceive themselves in
        alternate ways in imagined worlds, while discovering and connecting with
        their favorite artists. Whilst physical meets may not be possible given
        the current situation, Hydeout’s chat rooms will encapsulate the joy of
        meeting new people as fellow music lovers and fans are encouraged to use
        the safe space that will allow them to make new friends from every
        corner of the globe.
      </p>
    </h3>
  </div>
);

export default Overview;
