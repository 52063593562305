import axios from "axios";
import { APIUrl } from "../utils/config.util";

// logout(cb) {
//   this.authenticated = false;
//   cb();
// }

// will return true or false
export const isAuthenticated = () => {
  return axios
    .get(APIUrl + "/auth", {
      withCredentials: true,
    })
    .then((res) => {
      console.log("Auth.isAuthenticated", res.status, res);
      return res.status === 200;
    })
    .catch((err) => {
      console.log("Auth.isAuthenticated-ERROR", err);
      return false;
    });
};

// will return user object
export const isAuthorized = () => {
  return axios
    .get(APIUrl + "/auth", {
      withCredentials: true,
    })
    .then((res) => {
      console.log("Auth.isAuthorized", res.status, res);
      return res;
    })
    .catch((err) => {
      console.log("Auth.isAuthorized-ERROR", err);
      return null;
    });
};

export const login = (uniqueCodeIn, email) => {
  // console.log("uniqueCode POSTED", uniqueCodeIn, email);

  return axios(APIUrl + "/signin", {
    method: "POST",
    withCredentials: true,
    data: {
      ucode: uniqueCodeIn,
      email: email,
    },
  })
    .then((res) => {
      // at this point session cookie will be set when user is valid
      // console.log('RES login', res);
      return res;
    })
    .catch((e) => {
      console.log("ERROR calling post", e);
      return { data: { data: "not_allowed" } };
    });
};
