import React from "react";

import person1 from "../../assets/bio-wt.jpg";
import person2 from "../../assets/bio-ml.jpg";
import person3 from "../../assets/bio-h.jpeg";

import "./bio.styles.scss";

// very wide user profile card
const Bio1 = () => {
  return (
    <div className="bio-wrapper">
      <div className="bio">
        <div className="profile-pic">
          <img src={person1} alt="Chan Wee Teck"></img>
          <h3>Chan Wee Teck</h3>
          <span>Executive Producer, Hydeout</span>
        </div>
        <div className="profile-desc">
          <p>
            Cool Dad, Music Lover, Golf Nut, Barista Wanna Be, fearless of the
            unknown, excited by the new….
          </p>{" "}
          <p>
            Wee Teck is the quintessential ”slasher” driven by many passions,
            one of which is music. A classically trained violinist who listened
            to pop in the earlier days to producing immersive experiences today,
            music has been an integral and indispensable part of Wee Teck’s
            personal and professional life.
          </p>
          <p>
            {" "}
            He has worked alongside clients to solve business and marketing
            challenges, helping them engage with customers and consumers
            leveraging insight driven integrated marketing solutions.
          </p>
          <p>
            {" "}
            During his 20 years in this industry, he had the good opportunity to
            work closely with numerous brands and led teams in delivering
            engaging and immersive brand experiences from the Beijing Olympics,
            large scale national consumer engagements and show productions and
            brand experiences for gaming IPs in the region.{" "}
          </p>
          <p>
            He was also involved in building insight driven integrated
            engagement as an offering for agencies, bringing together talents
            from different industries to help brands engage in dialogue with
            target audience that matters.{" "}
          </p>
          <p>
            Returning to Singapore 3 years ago, he was involved in building and
            growing expertise in delivering digital led integrated experiences
            for brands in the region. Most recently, he was involved in the
            planning and delivery of campaigns and activations for brands
            targeting audience in the consumer and B2B space.{" "}
          </p>
          <p>
            Wee Teck is the executive producer for Hydeout, bringing together a
            collective of talented professionals and music lovers to create the
            region’s only Multi-genre music destination for tastemakers and
            music lovers. Wee Teck currently also leads strategic planning and
            client engagement for the region based out of Singapore.
          </p>
        </div>
      </div>
    </div>
  );
};

const Bio2 = () => {
  return (
    <div className={`bio-wrapper`}>
      <div className="bio">
        <div className="profile-pic">
          <img src={person2} alt="Mark Lim"></img>
          <h3>Mark Lim</h3>
          <span>Lead Show Producer</span>
        </div>
        <div className="profile-desc">
          <p>
            Making the impossible happen, is one of the core life mantras of
            Mark, who has overseen some of the biggest music production
            festivals and shows in Asia and the Middle East. One. Being closely
            connected to the mainstream and underground music scenes, Mark has
            constantly pushed the boundaries with his unorthodox approaches,
            always seeking to create a unique experience for consumer brand
            experiences.
          </p>
          <p>
            Combining his lust for knowledge, his drive to create unique
            experiences and his believe to never only look for the easy way out,
            he spearheaded the filming of Hydeout: The Prelude in extremely
            challenging situations during the Covid-19 period.
          </p>
          <p>
            Bagging 10 years of local and global experience in the industry, he
            has led teams in creating and producing multiple internationally
            renowned event assets and intellectual properties, where he has seen
            more than 1 million visitors go through the doors of his shows.
          </p>
          <p>
            Mark is the Lead Show Producer, Talent Buyer and Creative Driver for
            Hydeout, facilitating the myriad of talent showcases within the
            one-of-a-kind interactive platform build for music lovers,
            transcending the norms of a virtual music festival.
          </p>
        </div>
      </div>
    </div>
  );
};

const Bio3 = () => {
  return (
    <div className="bio-wrapper">
      <div className="bio">
        <div className="profile-pic">
          <img src={person3} alt="Henrik"></img>
          <h3>Henrik Axlund</h3>
          <span>Creative Director & Partner at MacWell</span>
        </div>
        <div className="profile-desc">
          <p>
            Henrik Axlund is a Creative Director and Partner at MacWell, a
            Helsinki based company that specializes in strategic Brand and
            Product marketing.
          </p>
          <p>
            Henrik started his visual journey over twenty years ago as a VJ
            supporting electronic music acts at festivals around Europe, and
            this fascination in visual communication led him to the world of 3D
            animation and effects.
          </p>
          <p>
            Henrik has been leading global product launches and brand
            experiences for some of the worlds most high tech companies, such as
            Microsoft, Nokia and Qualcomm. This experience in emerging and
            disruptive tech has helped him pioneer new creative opportunities in
            Virtual Reality, immersive digital experiences and real time 3D
            production.
          </p>
          <p>
            As a new father, his talent and passion for technology and visual
            storytelling will surely inspire the next generation to come.”
          </p>
        </div>
      </div>
    </div>
  );
};

const Bios = (props) => {
  return (
    <>
      <div className={props.className}>
        <h1 style={{ marginTop: "30px" }}>Meet Our Panelists</h1>
        <Bio1 />
        <Bio2 />
        <Bio3 />
      </div>
    </>
  );
};

export default Bios;
