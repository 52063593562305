import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../../utils/auth.util";
import UserContext from "../../UserContext";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <UserContext.Consumer>
            {(context) => {
              console.log("Protected Route context", context);
              return isAuthenticated() ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{ pathname: "/login", state: { from: props.location } }}
                />
              );
            }}
          </UserContext.Consumer>
        );
      }}
    />
  );
};

export default ProtectedRoute;
