import React from "react";
import { withRouter } from "react-router-dom";
import { Message } from "semantic-ui-react";

import "./login.styles.scss";
import "semantic-ui-css/semantic.min.css";

import CustomButton from "../../components/custom-button/custom-button.component";
import FormInput from "../../components/form-input/form-input.component";
import { login } from "../../utils/auth.util";
import UserContext from "../../UserContext";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userUniqueCode: "",
      email: "",
      isShowMessage: false,
      user: {},
    };
  }

  handleSubmit = async (event, context) => {
    event.preventDefault();
    console.log("LOGIN CONTEXT", context);

    this.setState({ isShowMessage: false });

    const { userUniqueCode, email } = this.state;
    const { history } = this.props;
    try {
      // this validateUser is much more stable to call to firebase
      await login(userUniqueCode, email).then((res) => {
        // console.log('DATA from login', res);
        let isValidUser = res.status === 200 && res.data.result === "logged_in";

        // document.cookie = `tokenxcl=${data.data.token}`; // no more cookies!
        // if VALID jump to audience page (post-login area)
        if (isValidUser) {
          // since it's login, we don't use Auth.isAuthenticated()
          this.setState({ isShowMessage: false });
          // TODO set user context too here!
          context.setUser(res.data.userInfo);
          // set user to UserContext
          history.push("/audience");
        } else {
          this.setState({ isShowMessage: true });
        }
      });
    } catch (error) {
      console.error(`ERROR - Login, ${error}`);
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    const { history, match } = this.props;
    console.log("HISTORY", history, match);
    const uniqueCode = history.location.search.substring(1);
    if (uniqueCode) {
      this.setState({ userUniqueCode: uniqueCode });
    } else {
      console.log(
        `ERROR - to Login you'll need valid Meeting Unique Code Emailed to you`
      );
    }
  }

  render() {
    const { email } = this.state;
    return (
      <UserContext.Consumer>
        {(context) => {
          return (
            <div className="login">
              <h2 className="title">Login</h2>
              <span>Login With Your Email</span>
              <form
                className="sign-up-form"
                onSubmit={(evt) => {
                  this.handleSubmit(evt, context);
                }}
              >
                <FormInput
                  type="email"
                  value={email}
                  name="email"
                  onChange={this.handleChange}
                  label="Email"
                  required
                />
                <CustomButton>Login</CustomButton>
                {this.state.isShowMessage ? (
                  <Message negative>
                    <Message.Header>
                      Invalid email or unique code
                    </Message.Header>
                    <p>Please contact your organizer.</p>
                  </Message>
                ) : null}
              </form>
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default withRouter(Login);
