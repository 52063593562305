import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import UserContext from "./UserContext";
import Audience from "./pages/audience/audience.component";
import Register from "./pages/register/register.component";
import RegisterSuccess from "./pages/register/register-success.component";
import Login from "./pages/login/login.component";
import ProtectedRoute from "./components/protected-route/protected-route.component";
import UserInfo from "./components/user-info/user-info.component";
import { isAuthorized } from "./utils/auth.util";
import Admin from "./pages/admin/admin.component";
import Home from "./pages/home/home.component";
import logo from "./assets/logo-hydeout-prelude.png";
import partner1 from "./assets/logo-klook.png";
import partner2 from "./assets/logo-festicket.png";

import "./App.scss";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.setUser = (usr) => {
      console.log("App.js USR", usr);
      this.setState({ user: usr });
    };

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      user: null,
      setUser: this.setUser,
    };
  }

  showRegisterButton(path) {
    switch (path) {
      case "/":
        return true;
      default:
        // all other routes don't show Register Free button~
        return false;
    }
  }

  toggleBurger = () => {
    const nav = document.querySelector(".nav-links");
    nav.classList.toggle("nav-active");
  };

  async componentDidMount() {
    // since http is stateless we're going to hit server to send cookie (if exist)
    const result = await isAuthorized();
    if (result) {
      console.log("App.js Mounted with result", result.data.result);
      // setUser for App.js version (not using setUser() from this.state)
      this.setState({ user: result.data.result }, () =>
        console.log("App.js SET USER", this.state.user)
      );
    }
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        <div className="bodyWrapper">
          <div className="body">
            <div className="wrapper">
              <div className="content">
                <Switch>
                  <Route exact path="/" component={Home} />
                  <ProtectedRoute exact path="/audience" component={Audience} />
                  <Route exact path="/register" component={Register} />
                  <Route
                    path="/registered"
                    render={(props) => <RegisterSuccess {...props} />}
                  />
                  <Route exact path="/login" component={Login} />
                  <ProtectedRoute path="/admin" component={Admin} />
                </Switch>
              </div>
              <div className="footer">
                <div>Copyright © 2021 Excelsis Conceptz Pte. Ltd.</div>
              </div>
            </div>
          </div>
        </div>
      </UserContext.Provider>
    );
  }
}

export default withRouter(App);
