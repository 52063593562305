import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
// import axios from "axios";
// import { APIUrl } from "../utils/config.util";

const config = {
  apiKey: "AIzaSyBjNrR39xD6OgtyEZ_ximaU-0ZNscfAyE0",
  authDomain: "xcl-event-db.firebaseapp.com",
  databaseURL: "https://xcl-event-db.firebaseio.com",
  projectId: "xcl-event-db",
  storageBucket: "xcl-event-db.appspot.com",
  messagingSenderId: "859154808646",
  appId: "1:859154808646:web:cba7cedf9874ac0b7bef47",
  measurementId: "G-B66Q41C08L",
};

export const createNewChatDocument = async (chatObj) => {
  const { name, time, msg } = chatObj;
  try {
    let chatRef = await firestore.collection("chats").add({
      name,
      time,
      msg,
    });
    console.log("CREATE NEW CHAT", chatRef.id, msg);
    return chatRef.id;
  } catch (error) {
    console.log("ERROR inserting Chat object", error.message);
  }
  return null;
};

export const insertNewSignal = async (signal) => {
  try {
    const signalRef = await firestore.collection("signals").add({
      status: signal,
    });
    console.log("inserting new signal", signal, signalRef.id);
  } catch (error) {
    console.log("ERROR creating Signal record", error.message);
  }
};

export const deleteSignal = async (signal) => {
  try {
    return firestore
      .collection("signals")
      .where("status", "==", signal)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          firestore.collection("signals").doc(doc.id).delete();
          console.log("DELETION doc", doc);
        });
      });
  } catch (error) {
    console.log("ERROR deleting Signal record", error.message);
  }
};

export const createUserProfileDocument = async (user) => {
  let uniqueCode = ""; // hydeout-microsite combined login

  if (!user) return { result: "USER_FROM_FORM_EMPTY" };

  const usersRef = firestore
    .collection("users")
    .where("email", "==", user.email);
  const snapshot = await usersRef.get();
  console.log("createUserProfileDocument snapshot.empty", snapshot.empty);
  if (snapshot.empty) {
    // if not exist we want to save that user into firebase db (firestore)
    const { firstName, lastName, email } = user;
    const createdAt = new Date();
    const lastUpdated = createdAt;
    // const videoSessionId = ''; //=> TODO separate table
    const lastLogin = null;
    const lastLogout = null;
    const role = "user";
    const updatedBy = "system-registration";

    try {
      let userRef = await firestore.collection("users").add({
        firstName,
        lastName,
        email,
        createdAt,
        lastLogin,
        lastLogout,
        role,
        lastUpdated,
        updatedBy,
      });
      console.log("creating user profile", {
        firstName,
        lastName,
        email,
        createdAt,
      });
      console.log("USERREF.ID", userRef.id, userRef);

      uniqueCode = userRef.id;
      userRef.update({ uniqueUserCode: userRef.id });
      // for hydeout which don't need separate register-login mechanism
      // we're combining register as login, so upon success give back the
      // unique code too
      return { result: "SUCCESS", uniqueCode: uniqueCode };
    } catch (error) {
      console.log("ERROR creating user", error.message);
    }
  } else {
    // if snapshot not empty show email has been registered
    console.log("CREATE USER PROFILE EXIST ALR", snapshot.docs[0].id);
    return { result: "EMAIL_EXIST", uniqueCode: snapshot.docs[0].id };
  }
};

export const getAllUsers = () => {
  let users = [];
  return firestore
    .collection("users")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log(`Firebase getAllUsers - user`, doc.id, doc.data());
        const {
          firstName,
          lastName,
          email,
          role,
          lastLogin,
          uniqueUserCode,
        } = doc.data();
        users.push({
          // xclid: doc.id,
          firstName,
          lastName,
          email,
          role,
          lastLogin,
          uniqueUserCode,
        });
      });
      // console.log(`Firebase users`, users);
      return users;
    });
};

export const getActivityReport = () => {
  let activities = [];
  return firestore
    .collection("activities")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(`Firebase getActivityReport - row`, doc.id, doc.data());
        activities.push(doc.data());
        console.log(`Firebase users`, activities);
      });
      return activities;
    });
};

firebase.initializeApp(config);

// this auth will be used in App.js to subscribe the onAuthStateChanged
// the same auth instance will be use to sign in in SignIn component
//         auth
//       /      \
//    SignIn   App.js
export const auth = firebase.auth();
export const firestore = firebase.firestore();

// setup using Google Oauth
// const provider = new firebase.auth.GoogleAuthProvider();
// we want to display google login popup, setup the popup
// provider.setCustomParameters({ prompt: 'select_account' });

// export const signInWithGoogle = () => auth.signInWithPopup(provider); // using the popup
// please make sure also ENABLE Google Oauth configuration at console.firebase.google.com
// under your account or project with the same API KEY above

export default firebase;
