import React from "react";
import "./custom-button.styles.scss";

const CustomButton = ({
  children,
  isCta,
  inverted,
  isDisabled,
  ...otherProps
}) => {
  return (
    <button
      className={`${inverted ? "inverted" : ""} 
      ${isCta ? "isCta" : ""} 
      ${isDisabled ? "disabled" : ""} 
      custom-button`}
      {...otherProps}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default CustomButton;
