import axios from "axios";
import { APIUrl } from "../utils/config.util";

export const sendEmail = (emailAddress, isReminder = false) => {
  return axios(APIUrl + "/sendEmail", {
    method: "POST",
    withCredentials: true,
    data: { email: emailAddress, isReminder: isReminder },
  })
    .then((res) => {
      console.log("sendEmail res,isReminder", res, isReminder);
      return res;
    })
    .catch((e) => {
      console.log("ERROR sendEmail", e);
    });
};

export const updateAccess = (id, newRole) => {
  return axios(APIUrl + "/update-access", {
    method: "POST",
    withCredentials: true,
    data: { user: { xclid: id, role: newRole } },
  })
    .then((res) => {
      console.log("updateAccess res", res);
      return res;
    })
    .catch((e) => {
      console.log("ERROR updateAccess", e);
    });
};

export const xyz = (scriptToremove) => {
  let allsuspects = document.getElementsByTagName("script");
  for (let i = allsuspects.length; i >= 0; i--) {
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute("src") !== null &&
      allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1
    ) {
      allsuspects[i].parentNode.removeChild(allsuspects[i]);
    }
  }
};

export const reformatDateColumn = (
  collection,
  columnName,
  secondsOnly = false
) => {
  return collection.map((item) => {
    if (item[columnName]) {
      // guard when time column is empty
      const dt = new Date(item[columnName].seconds * 1000);
      const dateFormat = secondsOnly
        ? `${dt.toLocaleTimeString()}`
        : `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
      return Object.assign({}, item, {
        [columnName]: dateFormat,
      });
    } else return item;
  });
};

export const formatDate = (date) => {
  const h = "0" + date.getHours();
  const m = "0" + date.getMinutes();
  return `${h.slice(-2)}:${m.slice(-2)}`;
};
