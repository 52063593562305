import React from 'react';
import Tabs from '../../components/tabs/tabs.component';
import Chatbox from '../../components/chatbox/chatbox.component';

const AudienceTabs = () => {
  const tabs = [
    {
      id: 1,
      name: 'Q&A',
      title: '',
      content: <Chatbox/>,
    },
  ];
  return (
    <React.Fragment>
      <Tabs tabs={tabs} />
    </React.Fragment>
  );
};

export default AudienceTabs;
