import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./home.styles.scss";
import Overview from "../../pages/overview/overview.component";
import Bios from "../../components/speakers/bio.component";
import VideoPlayer from "../../components/video-player/video-player.component";

const Home = (props) => {
  // useEffect(() => {
  //   // TODO: CHANGE below date to 19 Jan 4PM SGT
  //   const threshold =
  //     new Date("29 Jan 2021 23:59:00").getTime() +
  //     new Date("29 Jan 2021 23:59:00").getTimezoneOffset() * 60000;
  //   const now = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
  //   if (now > threshold) {
  //     const { history } = props;
  //     // Make default page as Register page if current time
  //     // greater than threshold
  //     history.push("/register");
  //   }
  // }, [props]);

  return (
    <div className="home">
      <h1 className="home-title">Wedding of Zi Hao & Yvonne</h1>
      <div id="myVideo" className="home-video">
        <VideoPlayer
          wowzaPlayerClass={""}
          wowzaUrl={
            "https://cdn3.wowza.com/1/ZXg2S2duc21IY3B0/WXRnNlRG/hls/live/playlist.m3u8"
          }
        />
        {/* <iframe
          title="loopVideo"
          src="https://player.vimeo.com/video/499506490?controls=1&autoplay=1&loop=1&muted=1&#t=1s&title=0"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe> */}
      </div>
      {/* <Overview className="home-overview" />
      <Bios className="home-biographies" /> */}
    </div>
  );
};

export default withRouter(Home);
