import React from "react";
import "./chatbox.styles.scss";
import Message from "./message.component";
import CustomButton from "../custom-button/custom-button.component";
import UserContext from "../../UserContext";
import {
  firestore,
  createNewChatDocument,
} from "../../firebase/firebase.utils";
import { reformatDateColumn } from "../../utils/service.util";

class Chatbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      inputBox: "",
    };
  }

  handleDbEvent = (change, msgs, isFirstLoad) => {
    if (change.type === "added") {
      const id = change.doc.id;
      const chat = change.doc.data();
      const newMsg = {
        id,
        ...reformatDateColumn([chat], "time", true)[0],
      };
      // console.log("CHATBOX - Incoming msg:", msgs);
      msgs.push(newMsg);

      if (!isFirstLoad) {
        // console.log("TRYING TO SET MSG2", msgs);
        // console.log("THIS in handleDBEvent", this);
        this.setState({ messages: msgs });
        // setInputBox("asdf");
      }
    }
    if (change.type === "modified") {
      console.log("Modified msg: ", change.doc.data());
    }
    if (change.type === "removed") {
      console.log("Removed msg: ", change.doc.data());
    }
  };

  // first load
  componentDidMount() {
    // init firestore listeners
    const thisComponent = this;
    let msgs = [];
    let isFirstLoad = true;
    firestore
      .collection("chats")
      .orderBy("time")
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          thisComponent.handleDbEvent(change, msgs, isFirstLoad);
        });
        console.log("TRYING TO SET MSG1", msgs);
        thisComponent.setState({ messages: msgs });
        thisComponent.scrollChatbox(500);
        isFirstLoad = false;
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  // useEffect(() => {
  //   scrollChatbox(500);
  //   console.log("DID IT REACH HERE???WTF", messages);
  // }, [messages, inputBox]);

  scrollChatbox = (delay) =>
    setTimeout(() => {
      const conv = document.getElementsByClassName("conversations")[0];
      if (conv) {
        conv.scrollTop = conv.scrollHeight;
      }
    }, delay);

  sendMessage = async (event, context) => {
    event.preventDefault();
    if (this.state.inputBox === "") return;
    const { firstName, lastName } = context.user;
    await createNewChatDocument({
      name: `${firstName} ${lastName}`,
      time: new Date(),
      msg: this.state.inputBox,
    });

    this.scrollChatbox(200);
    this.setState({ inputBox: "" });
  };

  handleInputMsg = (event) => {
    const { value } = event.target;
    this.setState({ inputBox: value });
  };

  render() {
    return (
      <UserContext.Consumer>
        {(context) => {
          return (
            <div className="chatbox">
              <div className="conversations">
                {this.state.messages.map((message) => (
                  <Message key={message.id} message={message} />
                ))}
              </div>
              <form
                className="input"
                onSubmit={(e) => this.sendMessage(e, context)}
              >
                <input
                  type="text"
                  label="Type your question here"
                  value={this.state.inputBox}
                  onChange={this.handleInputMsg}
                />
                <CustomButton>Ask</CustomButton>
              </form>
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default Chatbox;
